import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Url } from '../../shared/constants/url-constants';

const authHeaders = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class TogetherModeModalService {
  togetherModeEnabled: boolean;
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: HTMLDocument
  ) {}

  public readonly showEnterTogetherMode = new Subject<any>();
  public readonly hideEnterTogetherMode = new Subject<any>();

  showEnterTogetherMode$ = this.showEnterTogetherMode.asObservable();
  hideEnterTogetherMode$ = this.hideEnterTogetherMode.asObservable();
  
  openModal() {
    this.showEnterTogetherMode.next(void 0);
    const usernameInput = this.document.getElementById('id-username')
    setTimeout(() => {
      usernameInput.focus();
    })
  }

  closeModal() {
    this.hideEnterTogetherMode.next(void 0);
  }

  enterTogetherMode(formData: any) {
    const body = JSON.stringify(formData);
    return this.http.post<any>(`${Url.USER}/together-mode/login`, body, { headers: authHeaders, observe: 'response' })
    .pipe(
      tap(response => {
        this.setTogetherModeTrue();
        this.hideEnterTogetherMode.next(void 0);
      })
    );
  }

  exitTogetherMode() {
    const body = {};
    return this.http.post<any>(`${Url.USER}/together-mode/logout`, body, { headers: authHeaders, observe: 'response' })
    .pipe(
      tap(response => {
        this.setTogetherModeFalse();
        this.hideEnterTogetherMode.next(void 0);
      })
    );
  }

  resolveTogetherModeStatus() {
    localStorage.getItem('sessionTM') ? this.togetherModeEnabled = true : this.togetherModeEnabled = false; 
  }

  getTogetherModeEnabledStatus() {
    return this.togetherModeEnabled;
  }

  setTogetherModeTrue() {
    const encodedString = Base64.encode('enabled');
    localStorage.setItem('sessionTM', encodedString);
    this.togetherModeEnabled = true;
  }

  setTogetherModeFalse() {
    localStorage.removeItem('sessionTM');
    this.togetherModeEnabled = false;
    if (this.router.url.includes('/plan/induction')) {
      this.router.navigateByUrl('/plan');
    }
  }

}
